<template>
  <b-list-group>
    <span class="vertical-line">
    <b-list-group-item v-for="(item, key) in transportDetails" :key="key">
      <!-- Top row is shown without expanding so it is ignored here -->
      <div v-if="key > 0" class="title">{{ item.address }} - {{ messages.getLabelStatus({ status: item.status }) }}</div>
      <div v-if="key > 0" class="subtitle">{{ item.date_time }}</div>
    </b-list-group-item>
    </span>
  </b-list-group>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'MobileTransportDetailsItem',
  data () {
    return {
      messages
    }
  },
  props: {
    transportDetails: {
      type: Array,
      default () {
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-group-item {
  padding: 0;
  border: 0;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  line-height: 1;
  .title {
    margin: 0;
    padding: 0;
    color: $title;
    min-height: 1em;
  }
  .subtitle {
    color: $subtitle;
    min-height: 1em;
    margin: 0;
    padding: 0;
  }
}
</style>
