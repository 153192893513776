<template>
  <b-list-group-item
    class="p-0 list-item"
  >
    <div
      data-x="materialCode"
      class="flex-column align-items-start material-code-group"
    >
      <!-- Show link for specific customer. -->
      <span v-if="materialCodeUrl">
        <b-link
          :href="materialCodeUrl"
          target="_blank"
          class="material-code"
        >
          {{ materialCodeGroup.materialCode || `(${messages.getLabelNoMaterialCode()})` }}
        </b-link>
        </span>
      <h2
        class="material-code"
        v-else
      >
        {{ materialCodeGroup.materialCode || `(${messages.getLabelNoMaterialCode()})` }}
      </h2>
    </div>
    <ul
      v-for="(materialCodeItem, index) in materialCodeGroup.materialCodeItems"
      :key="index"
      :data-stage="materialCodeItem._stage"
      :data-purchase-order-doc-number="materialCodeItem.purchaseOrderDocNumber"
      :data-reservation-doc-number="materialCodeItem.reservationDocNumber"
      :data-service-order="materialCodeItem.serviceOrder"
      class="py-0 my-0 list-unstyled pad-for-chevron"
    >
      <mobile-delivery-date-header
        :material-code-item="materialCodeItem"
      />
      <mobile-destination-item
        v-if="showDestinationItem(materialCodeItem)"
        class="my-2"
        :material-code-item="materialCodeItem"
      />
      <mobile-delivery-item
        v-if="showDeliveryItem(materialCodeItem)"
        class="my-2"
        :material-code-item="materialCodeItem"
      />
      <mobile-confirmation-item
        v-if="showConfirmationItem(materialCodeItem)"
        class="my-2"
        :material-code-item="materialCodeItem"
      />
    </ul>
  </b-list-group-item>
</template>

<script>
import { LA } from '@/constants/shared-constants'
import { messages } from '@/utils/strings'
import MobileDestinationItem from '@/components/OrderDetails/MobileDestinationItem'
import MobileDeliveryItem from '@/components/OrderDetails/MobileDeliveryItem'
import MobileConfirmationItem from '@/components/OrderDetails/MobileConfirmationItem'
import MobileDeliveryDateHeader from '@/components/OrderDetails/MobileDeliveryDateHeader'
import { formatResponseDate } from '@/components/StatusMonitor/formatters'
import { trackingStatusStages } from '@/components/StatusMonitor/tracking-status'

export default {
  name: 'MobileMaterialGroup',
  data () {
    return {
      messages
    }
  },
  props: {
    materialCodeGroup: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    MobileDestinationItem,
    MobileDeliveryItem,
    MobileConfirmationItem,
    MobileDeliveryDateHeader
  },
  filters: {
    formatResponseDate
  },
  computed: {
    materialCodeUrl () {
      return this.$store.getters.createMaterialCodeUrl(
        this.materialCodeGroup.materialCode
      )
    },
    formattedPurchaseOrderInfo: function () {
      return this._formattedPuchaseOrderInfo || messages.getLabelNoPurchaseOrder()
    }
  },
  methods: {
    showDestinationItem: function (materialCodeItem) {
      // See: https://verkkoaps.atlassian.net/browse/CPL-272
      if (materialCodeItem.deliveryAddress || materialCodeItem.addressCode || materialCodeItem._stage === trackingStatusStages.PURCHASE_ORDER_GOODS_RECEIPT_ITEM) {
        return true
      }
      return false
    },
    showDeliveryItem: function (materialCodeItem) {
      // See: https://verkkoaps.atlassian.net/browse/CPL-272
      if (materialCodeItem?.transportDetails?.length > 0 ||
          materialCodeItem._stage === trackingStatusStages.DELIVERY_ORDER_ITEM ||
          (materialCodeItem._stage === trackingStatusStages.PURCHASE_ORDER_CONFIRMATION_ITEM && materialCodeItem.purchaseOrderConfirmationCategory === LA) ||
          materialCodeItem._stage === trackingStatusStages.PURCHASE_ORDER_GOODS_RECEIPT_ITEM) {
        return true
      }
      return false
    },
    showConfirmationItem: function (materialCodeItem) {
      // See: https://verkkoaps.atlassian.net/browse/CPL-272
      //
      // Always show this row, but for this to have any real use the stage should be one of
      // 'purchaseOrderItem', 'salesOrderItem' or 'deliveryOrderItem'.
      //
      // Because this is always shown, it doubles as a "fallback" item to show at least something if
      // both showDestinationItem and showDeliveryItem are false
      return true
    }
  }
}
</script>

<style scoped lang="scss">
/* TODO: Fix CSS */
$small-margin: 20px;
$medium-margin: 40px;
$big-margin: 60px;
$small-icon-size: 24px;

.small-icon {
  font-size: $small-icon-size;
}
.info {
  color: $info;
}
.success {
  color: $success;
}

.material-code-group {
  background-color: $secondary;
}
.material-code {
  color: $primary;
  margin-left: $small-margin;
  font-size: $category-title-font-size
}
.material-code-row {
  .delivery-date-row {
    h3 {
      /* TODO: Vertical align with icon. */
      margin-left: $small-margin;
      font-size: $category-title-font-size
    }
  }
  .material-code-item {
    margin-left: $medium-margin;
  }
}
a {
  text-decoration: underline;
}
</style>
