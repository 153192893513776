<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    role="presentation"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBase',
  props: {
    width: {
      type: [Number, String],
      default: 26
    },
    height: {
      type: [Number, String],
      default: 26
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    viewBox: {
      type: String,
      default: '0 0 96 96'
    }
  }
}
</script>

<style scoped>
#svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
