import colorVariables from '@/assets/css/custom.scss'
import { trackingStatusStages, isLogisticsFlowLocated } from '@/components/StatusMonitor/tracking-status'
import { DELIVERED, DELIVERY_FAILED, AB, LA } from '@/constants/shared-constants'
import { formatResponseDate } from './formatters'
const green = colorVariables.green
const gray = colorVariables.gray400
const red = colorVariables.red
const blue = colorVariables.etaColor
// const yellow = colorVariables.yellow
// const info = colorVariables.info

/**
 * Determine the stage/phase of the tracking status and return corresponding *confirmation* icon color.
 *
 * @see https://app.lucidchart.com/documents/edit/999de7e3-8a31-48bb-9b36-2cefcd923772/1eelgPoUOGKz
 *   for documentation and flow chart.
 * @param {object} trackingStatusItem
 * @param {string} trackingStatusItem.stage - TrackinStatusItem stage.
 * @param {string} trackingStatusItem.deliveryStatus - Delivery status from the Parts API TrackingStatus item.
 * @param {string} trackingStatusItem.deliveryType - Delivery type from the Parts API TrackingStatus item.
 * @param {string} trackingStatusItem.salesOrderDocNumber - Sales order document number.
 * @returns {string|null} - The color code for the confirmation icon or null if icon should be hidden.
 */
function getConfirmationColor ({ stage, deliveryStatus, deliveryType, salesOrderDocNumber, purchaseOrderConfirmationCategory }) {
  if (stage === trackingStatusStages.RESERVATION_ITEM) {
    return colorVariables.gray
  }

  if (stage === trackingStatusStages.DELIVERY_ORDER_ITEM) {
    if (isLogisticsFlowLocated({ deliveryType })) {
      return colorVariables.gray
    }
    return colorVariables.green
  }

  // Stage is not DeliveryOrderItem, continue with the flow chart.
  if (deliveryStatus === DELIVERED) {
    return colorVariables.gray
  }

  if (stage === trackingStatusStages.PURCHASE_ORDER_ITEM) {
    return colorVariables.yellow
  }

  if (stage === trackingStatusStages.SALES_ORDER_ITEM) {
    return colorVariables.green
  }

  if (stage === trackingStatusStages.SALES_ORDER_SCHEDULE_LINE_ITEM) {
    return colorVariables.green
  }

  if (stage === trackingStatusStages.PURCHASE_ORDER_CONFIRMATION_ITEM) {
    if (purchaseOrderConfirmationCategory === LA) {
      return colorVariables.gray
    }
    return colorVariables.green
  }

  if (salesOrderDocNumber) {
    // See https://verkkoaps.atlassian.net/browse/CPL-320
    return colorVariables.gray
  }
  // Did not match any rule in the flow chart, so let's default to no icon.
  return null
}

/**
 * Determine the stage/phase of the tracking status and return corresponding *delivery* icon color.
 *
 * @param {object} trackingStatusItem - Item from Tracking Status API response (TrackinStatus.items[])
 * @param {string} trackingStatusItem.deliveryStatus - Delivery status from the Parts API TrackingStatus item.
 * @param {string} trackingStatusItem.deliveryOrderDocNumber Delivery order document number from the Parts API TrackingStatus item.
 * @param {string} trackingStatusItem.deliveryType - Delivery type from the Parts API TrackingStatus item.
 * @param {string} trackingStatusItem.purchaseOrderConfirmationCategory - AB or LA
 * @param {string} trackingStatusItem.trackingNumber - Delivery is on going if trackingNumber is available
 * @returns {string|null} - The color code (gray, green, yellow) for the confirmation icon or null if icon should be hidden.
 */
function getDeliveryColor ({ deliveryStatus, deliveryOrderDocNumber, deliveryType, purchaseOrderConfirmationCategory, trackingNumber }) {
  if (purchaseOrderConfirmationCategory === LA) {
    return colorVariables.yellow
  }

  if (deliveryStatus === DELIVERED) {
    // If delivery is tracked from carrier, all items have “Delivered” status from tracking
    return colorVariables.gray
  } else if (deliveryStatus === DELIVERY_FAILED) {
    // So far only SLS is using this deliveryStatus.
    return colorVariables.red
  }

  if (deliveryOrderDocNumber) {
    if (deliveryType === 'NA') {
      return colorVariables.yellow
    } else if (trackingNumber !== null && trackingNumber !== undefined) {
      return colorVariables.green
    }
  }
  return null
}

/**
 * Determine the destination icon color
 * @param {object} options
 * @param {string} options.deliveryStatus - Delivery status from the Parts API TrackingStatus item.
 */
function getDestinationColor ({ deliveryStatus }) {
  if (deliveryStatus === DELIVERED) {
    return colorVariables.green
  } else {
    return colorVariables.gray
  }
}

/**
 * Try to prioritize which should the icon color be in the order row.
 *
 * This does not differentiate if the color is for confirmation, delivery or address icons.
 *
 * @param {array} colors - Array of color string.
 * @returns {string} - A color code for icon.
 */
function getOrderRowIconColor (colors) {
  let priorityColor = null
  for (const color of colors) {
    if (color === colorVariables.green) {
      // Green is top priority, if we found one match we are done here.
      return color
    }
    if (color === colorVariables.yellow) {
      // Store yellow as the most important color.
      priorityColor = colorVariables.yellow
    } else if (color === colorVariables.gray && priorityColor !== colorVariables.yellow) {
      // Don't override yellow as that is already more important than gray.
      priorityColor = colorVariables.gray
    }
  }
  if (priorityColor === colorVariables.gray) {
    // "Icons are missing from the header line if all the item icons are
    // grey/missing à so on the item level (expanded) there is always an
    // Icon on confirmation as I mentioned, but it can be missing from header
    // line if not available/applicable in any of the items.
    //
    // (why missing from header and not grey as in item à easier to check in
    // colorblind mode with quick check what is overall status when we see
    // only yellow/green icons there)"
    //
    // In short: If all the iconds in item level are grey or missing (null),
    // Then the order row icon should not be shown.
    return null
  }
  return priorityColor
}

function getInfoTextColor (params) {
  const deliveryStatus = params.deliveryStatus
  const readyToGo = params.actionMap.READY_TO_GO
  const toBePurchased = params.actionMap.TO_BE_PURCHASED
  if (deliveryStatus === toBePurchased) {
    return colorVariables.yellow
  }
  if (deliveryStatus === readyToGo) {
    return colorVariables.green
  }
  return colorVariables.info
}

/**
 * Determine what or if pickup location icon should be visible, and its color.
 *
 * @param {string} deliveryType - Two possible types are `'Warehouse'` and
 *   `'Accesspoint'` and they decide the icon and visibility.
 * @param {string} deliveryStatus - If package has been delivered,
 *   `DELIVERED` or `'NA'`.
 * @param {string} deliveryAddress - delivery address, if available.
 * @param {string} addressCode - address code, if available.
 * @returns {object} Object meant for binded HTML class attribute
 *   (`:class="classes"`).
 */
function getDestinationIcon ({ deliveryType, deliveryStatus, deliveryAddress, addressCode }) {
  const iconClasses = {
    'icon-warehouse': false,
    'icon-location': false,
    green: false,
    gray: false
  }
  // Icon image
  if (deliveryType === 'AccessPoint') {
    iconClasses['icon-location'] = true
  } else if (deliveryAddress || addressCode) {
    iconClasses['icon-warehouse'] = true
  }
  const color = getDestinationColor({ deliveryStatus })
  if (color === colorVariables.green) {
    iconClasses.green = true
  } else if (color === colorVariables.gray) {
    iconClasses.gray = true
  }
  return iconClasses
}

/**
 * The function to return the delivery date color
 * @param {string} deliveryStatus We are only interested about status Delivered
 * @param {string} purchaseOrderConfirmationCategory LA or AB
 * @param {date} eta Estimated time of arrival or the real delivery time in case deliveryStatus
 * is Delivered
 * @param {string} _stage At which stage are we
 * @returns the color that is going to be used for the delivery date or null if there is no eta
 * and empty string if nothing matches
 */
function getDeliveryDateColor ({ deliveryStatus, purchaseOrderConfirmationCategory, eta, _stage }) {
  if (deliveryStatus === DELIVERED) {
    // final delivery recorded
    // green date without eta
    return green
  }
  const dateNow = formatResponseDate(new Date())
  if (eta && (eta < dateNow)) {
    // delivery date past eta and no final delivery recorded
    // red date with eta
    return red
  }
  if (eta && (purchaseOrderConfirmationCategory === LA || _stage === trackingStatusStages.DELIVERY_ORDER_ITEM)) {
    // LA confirmation or GSS/KONE Spares delivery schedule
    // green with eta
    return green
  }
  if (eta && (purchaseOrderConfirmationCategory === AB || _stage === trackingStatusStages.SALES_ORDER_SCHEDULE_LINE_ITEM)) {
    // AB confirmation or GSS sales order schedule details
    // blue with eta
    return blue
  }
  // No eta recorded
  return ''
}

export {
  getConfirmationColor,
  getDeliveryColor,
  getDestinationColor,
  getOrderRowIconColor,
  getInfoTextColor,
  getDestinationIcon,
  getDeliveryDateColor,
  gray,
  green,
  colorVariables
}
