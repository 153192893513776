<template>
  <div>
    <mobile-material-code-item
      icon="icon-assignment"
      :icon-color="confirmationIconColor"
      :title="purchaseOrderInfo"
      :subtitle="quantityDetails"
      :salesOrderDocFlow="salesOrderDocFlow"
      data-context="Confirmation item: Purchase order info and quantities"
      :data-sap-flow-type="sapFlowType"
    />
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
// import colorVariables from '@/assets/css/custom.scss'
import MobileMaterialCodeItem from '@/components/OrderDetails/MobileMaterialCodeItem'
import { formatResponseDate, formatPurchaseOrderInfo } from '@/components/StatusMonitor/formatters'
import { getConfirmationColor } from '@/components/StatusMonitor/colors'
import { TAGS } from '../../constants/tags'

export default {
  name: 'MobileConfirmationItem',
  components: {
    MobileMaterialCodeItem
  },
  props: {
    materialCodeItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  filters: {
    formatResponseDate
  },
  created () {
    this.GSS = TAGS.GSS
    this.LOCAL = TAGS.LOCAL
  },
  computed: {
    salesOrderDocFlow () {
      if (this.materialCodeItem.salesOrderDocNumber) {
        return this.GSS
      }
      return this.LOCAL
    },
    sapFlowType () {
      // sapFlowType is Inbound, Outbound or NA if not set
      if (this.materialCodeItem?.sapFlowType) {
        return this.materialCodeItem.sapFlowType
      }
      return 'NA'
    },
    confirmationIconColor: function () {
      return getConfirmationColor({
        stage: this.materialCodeItem._stage,
        deliveryStatus: this.materialCodeItem.deliveryStatus,
        deliveryType: this.materialCodeItem.deliveryType,
        salesOrderDocNumber: this.materialCodeItem.salesOrderDocNumber,
        purchaseOrderConfirmationCategory: this.materialCodeItem.purchaseOrderConfirmationCategory
      })
    },
    quantityDetails: function () {
      // TODO: Use generalised function to get the correct fields.
      // Quantity shown is following the logic from ExpandRow.
      const quantity = (
        this.materialCodeItem.deliveryQuantity ??
        this.materialCodeItem.salesOrderScheduleLineQuantity ??
        this.materialCodeItem.salesOrderQuantity ??
        this.materialCodeItem.purchaseOrderGoodsReceiptQuantity ??
        this.materialCodeItem.purchaseOrderConfirmationQuantity ??
        this.materialCodeItem.purchaseOrderQuantity ??
        this.materialCodeItem.reservationQuantity ??
        '?'
      )
      const unit = this.materialCodeItem.unit ?? messages.getFieldPc()
      const description = this.materialCodeItem.materialDescription ?? `(${messages.getLabelNoDescription()})`
      // TODO: See if we can do the formatting in the template.
      return `${quantity} ${unit}. ${description}`
    },
    purchaseOrderInfo: function () {
      return formatPurchaseOrderInfo(this.materialCodeItem)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
