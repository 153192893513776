<template>
  <div
    class="d-flex justify-content-center align-items-center"
  >
    <icon-base
      view-box="0 0 71 71"
      width="60px"
      height="60px"
      :icon-color="color"
    >
      <path d="M35.5,0L4.757,17.75V53.25L35.5,71l30.743-17.75v-35.5L35.5,0z M59.361,49.276L35.5,63.053L11.639,49.276V21.724L35.5,7.947l23.861,13.776V49.276z"/>
    </icon-base>
    <div
      id="slot"
    >
      <slot>
        <i
          class="icon-assignment"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import IconBase from './IconBase'

export default {
  name: 'IconHexagonMask',
  components: { IconBase },
  props: {
    color: {
      type: String,
      default: '#EDEFF0'
    }
  }
}
</script>

<style scoped lang="scss">
#slot {
  position: absolute;
  font-size: 20px;
}
.icon-assignment {
  color: $gray-400;
  font-size: 22px;
}
</style>
