<template>
  <page-layout>
    <template v-slot:topBar>
      <order-details-top-bar />
    </template>
    <order-details
      :order-details="orderDetails"
    />
  </page-layout>
</template>

<script>
import OrderDetails from '../components/OrderDetails/OrderDetails'
import PageLayout from '../components/PageLayout'
import OrderDetailsTopBar from '../components/OrderDetails/OrderDetailsTopBar'

export default {
  name: 'OrderDetailsPage',
  components: { OrderDetailsTopBar, PageLayout, OrderDetails },
  props: {
    orderDetails: {
      type: Object,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
