<template>
  <b-media tag="li" class="list-item">
    <h3 v-if="deliveryDateIsEta" class="date-header eta" data-context="ETA">
      <span :style="{ color: deliveryDateColor }">
        {{ eta | formatResponseDate | formatMissing(messages.getLabelNoEtaDate()) }} {{ messages.getFieldEta() }}
      </span>
      <span v-if="trackingNumber" class='tracking-number'>{{ trackingNumber }}</span>
    </h3>
    <h3 v-else class="date-header" data-context="Delivery Date">
      <span :style="{ color: deliveryDateColor }">{{ deliveryDate || eta | formatResponseDate }}</span>
      <span v-if="trackingNumber" class='tracking-number'>{{ trackingNumber }}</span>
    </h3>
  </b-media>
</template>

<script>
import { messages } from '@/utils/strings'
// import colorVariables from '@/assets/css/custom.scss'
import { formatResponseDate, formatMissing } from '@/components/StatusMonitor/formatters'
import { getDeliveryDateColor } from '@/components/StatusMonitor/colors'

export default {
  name: 'MobileDeliveryDateHeader',
  data () {
    return {
      messages
    }
  },
  props: {
    materialCodeItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  filters: {
    formatResponseDate,
    formatMissing
  },
  computed: {
    deliveryDateColor () {
      // console.log(this.materialCodeItem)
      return getDeliveryDateColor(this.materialCodeItem)
    },
    eta: function () {
      return this.materialCodeItem.eta
    },
    deliveryDateIsEta: function () {
      return this.materialCodeItem.deliveryDateIsEta
    },
    deliveryDate: function () {
      return this.materialCodeItem.deliveryDate
    },
    trackingNumber: function () {
      return this.materialCodeItem.trackingNumber
    }
  }
}
</script>

<style scoped lang="scss">
$small-margin: 20px;
$header-margin: 10px;
.date-header {
  margin: $header-margin $small-margin;
  font-size: $category-title-font-size
}
.eta {
  color: $eta-color;
}
.tracking-number {
  margin-left: 20px;
  color: $gray-900;
}
</style>
