import { builtStrings, messages } from '@/utils/strings'

function nullishLike (value) {
  // FIXME: Why is the value cast as a string?
  if (value === null || value === undefined || value === 'null' || value === 'undefined') {
    return true
  }
  return false
}

function formatMissing (value, message) {
  // console.log({ _: 'formatNull', value, message })
  if (nullishLike(value)) {
    return `(${message})`
  }
  return value
}

function formatResponseDate (value) {
  if (nullishLike(value)) {
    return ''
  }
  return builtStrings.getPageStatusContentDateMomentFormat({ dateString: value })
}

function removeLeadingZeros (text) {
  return text.replace(/^0+/, '')
}

// Not a filter
function formatPurchaseOrderInfo ({ purchaseOrderDocNumber, purchaseOrderDocItemNumber }) {
  // formats purchaseOrderDocNumber '00000001234' and item number '00020'
  // to '1234/20'
  return removeLeadingZeros(purchaseOrderDocNumber || messages.getLabelUnknownValueMarker()) + messages.getLabelRelatedValuesSeparator() + removeLeadingZeros(purchaseOrderDocItemNumber || messages.getLabelUnknownValueMarker())
}

export { formatMissing, formatResponseDate, formatPurchaseOrderInfo }
