<template>
  <mobile-material-code-item
    :icon="destinationIcon"
    :icon-color="destinationIconColor"
    :title="deliveryAddress"
    :subtitle="subtitle"
    :debug-stage="materialCodeItem._stage"
  />
  <!--
    TODO: Should the route items be listed here or in MobileMaterialCodeItem?
  -->
</template>

<script>
import { messages } from '@/utils/strings'
// import colorVariables from '@/assets/css/custom.scss'
import MobileMaterialCodeItem from '@/components/OrderDetails/MobileMaterialCodeItem'
import { formatResponseDate } from '@/components/StatusMonitor/formatters'
import { getDestinationIcon, getDestinationColor } from '@/components/StatusMonitor/colors'

export default {
  name: 'MobileDestinationItem',
  components: {
    MobileMaterialCodeItem
  },
  props: {
    materialCodeItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  filters: {
    formatResponseDate
  },
  computed: {
    subtitle: function () {
      // TODO: The UX design doesn't show what is supposed to be shown here.
      // It only has value "2nd line"
      return ''
    },
    destinationIcon: function () {
      // FIXME: Refactor and unify the icons. Choose class based logic or
      // class and color based logic. Do not mix. Be consisten.
      // console.log({ materialCodeItem: this.materialCodeItem })
      const iconClasses = getDestinationIcon(this.materialCodeItem)
      // console.log({ iconClasses })
      if (iconClasses['icon-warehouse']) {
        return 'icon-warehouse'
      }
      if (iconClasses['icon-location']) {
        return 'icon-location'
      }
      return null
    },
    destinationIconColor: function () {
      return getDestinationColor(this.materialCodeItem)
    },
    deliveryAddress: function () {
      return this.materialCodeItem.deliveryAddress || this.materialCodeItem.addressCode || `(${messages.getLabelNoAddressCode()})`
    },
    topDeliveryDetails: function () {
      return {
        address: this.materialCodeItem.deliveryAddress,
        date: this.materialCodeItem.deliveryDate
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
