<template>
  <b-media tag="li" class="list-item">
    <template v-slot:aside>
      <div class="align-icon">
        <i
          class="small-icon"
          :class="[ icon, showIcon ]"
          :style="{ color: iconColor }"
          data-context="Material Code Item Icon"
        ></i>
      </div>
    </template>
    <div
      class="main-wrapper"
      @click="expand"
    >
      <div class="text-wrapper">
        <div class="mt-0 title">
          {{ title }}
          <tag-badge
            v-if="showFlowBadge"
            :salesOrderDocFlow="salesOrderDocFlow"
          />
        </div>
        <div class="mb-0 subtitle">
          {{ subtitle }}
        </div>
      </div>
      <div class="chevron-wrapper">
        <div
          v-if="expandable"
          class="chevron-container"
        >
          <i class="icon-chevron-right"></i>
        </div>
      </div>
    </div>
    <!--
      TODO: Should the expandable items be here or in
      MobileDeliveryItem?
    -->
    <div style="display: none;">Placeholder for expandable items</div>
  </b-media>
</template>

<script>
import { messages } from '@/utils/strings'
// import colorVariables from '@/assets/css/custom.scss'

import { formatResponseDate } from '@/components/StatusMonitor/formatters'
import TagBadge from './TagBadge'
import { TAGS } from '../../constants/tags'

export default {
  name: 'MobileMaterialCodeItem',
  components: {
    TagBadge
  },
  props: {
    icon: {
      type: String,
      default () {
        return 'icon-maintenance' // Placeholder for debugging
      }
    },
    iconColor: {
      type: String,
      default () {
        return 'brown' // Placeholder for debugging
      }
    },
    title: {
      type: String,
      default () {
        return `(${messages.getLabelNoTitle()})`
      }
    },
    subtitle: {
      type: String,
      default () {
        return `(${messages.getLabelNoSubtitle()})`
      }
    },
    expandable: {
      type: Boolean,
      default () {
        return false
      }
    },
    salesOrderDocFlow: {
      type: String
    },
    transportDetails: {
      type: Array
    },
    sapFlowType: {
      type: String
    },
    debugStage: {
      type: String
    }
  },
  filters: {
    formatResponseDate
  },
  computed: {
    showIcon: function () {
      if (this.iconColor === null) {
        return 'hide'
      }
      return null
    },
    showFlowBadge () {
      return [TAGS.LOCAL, TAGS.GSS].includes(this.salesOrderDocFlow)
    }
  },
  methods: {
    expand () {
      if (!this.expandable) {
        return
      }
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
/* TODO: Fix CSS */
$small-margin: 20px;
$medium-margin: 40px;
$big-margin: 60px;
$small-icon-size: 24px;
$tiny-icon-size: 12px;

.small-icon {
  font-size: $small-icon-size;
}

.info {
  color: $info;
}
.success {
  color: $success;
}
.debug-color {
  color: #ec03fc;
}
.hide {
  visibility: hidden;
}
.align-icon {
  margin-top: 6px;
  width: $small-icon-size;
  /* For some reason icon-warehouse (and only that icon) aligns itself to left if
     this text-align: right; is not set.
  */
  text-align: right;
}
.main-wrapper {
  display: flex;
  flex-direction: row;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
  }

  .chevron-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
  }
}

.chevron-container {
  display: block;
  i {
    font-size: $tiny-icon-size;
    color: $primary;
  }
}

.list-item {
  padding-left: $medium-margin;
  .title {
    color: $title;
    min-height: 1.5em;
  }
  .subtitle {
    color: $subtitle;
    min-height: 1.5em;
  }
}

.material-code-group {
  background-color: $secondary;
  h2.material-code {
    color: $primary;
    margin-left: $small-margin;
    font-size: $category-title-font-size
  }
}
.material-code-row {
  .delivery-date-row {
    h3 {
      /* TODO: Vertical align with icon. */
      margin-left: $small-margin;
      font-size: $category-title-font-size
    }
  }
  .material-code-item {
    margin-left: $medium-margin;
  }
}
</style>
