// Tag name constants

/**
 * GSS: the parts are delivered through KONE Global Spares Supplies organisation.
 * Local: the parts are delivered locally from Warehouse or AccessPoint.
*/
const TAGS = {
  GSS: 'GSS',
  LOCAL: 'Local'
}

export { TAGS }
