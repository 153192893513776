<template>
  <!-- orderDocNumberTooltipList returns-->
  <span>
    <span v-if="salesOrderDocFlow === GSS">
      <b-badge
        pill
        variant="primary"
        class="badge-formatting gss-badge-color"
      >
        {{ messages.getLabelGss() }}
      </b-badge>
    </span>
    <span v-else-if="salesOrderDocFlow === LOCAL">
      <b-badge
        pill
        variant="primary"
        class="badge-formatting local-badge-color"
      >
        {{ messages.getLabelLocal() }}
      </b-badge>
    </span>
  </span>
</template>

<script>
import { TAGS } from '../../constants/tags'
import { messages } from '@/utils/strings'

export default {
  name: 'TagBadge',
  props: {
    salesOrderDocFlow: {
      type: String,
      required: false
    }
  },
  created () {
    this.GSS = TAGS.GSS
    this.LOCAL = TAGS.LOCAL
    this.messages = messages
  }
}
</script>

<style>
.badge-formatting {
  margin-left: 5px
}
.gss-badge-color {
  background-color: #0071b9;
}
.local-badge-color {
  background-color: #8D9194;
}
</style>
