<template>
  <b-list-group
    flush
    class="w-100"
  >
    <order-list-item
      :order-list-item="orderDetails"
    />
    <mobile-material-group
      :order-list-item="orderDetails"
      v-for="materialCodeGroup in materialCodeGroups"
      :key="materialCodeGroup.materialCode"
      :material-code-group="materialCodeGroup"
    />
  </b-list-group>
</template>

<script>
import OrderListItem from '@/components/StatusMonitor/Mobile/OrderListItem'
import MobileMaterialGroup from './MobileMaterialGroup'

export default {
  name: 'OrderDetails', // FIXME: Rename this to be more descriptive. This is only used in mobile view.
  components: { OrderListItem, MobileMaterialGroup },
  props: {
    orderDetails: {
      type: Object,
      required: false
    }
  },
  mounted () {
    // console.log({ _: 'OrderDetails.mounted', orderDetails: this.orderDetails, this: this })
  },
  computed: {
    /**
     * Convert desktop row-based items to more organised structure
     *
     * Developer's notes: It's an ugly hack, I know.
     */
    materialCodeGroups: function () {
      if (!this?.orderDetails?.expandRows) {
        // If the prop is not initialised or does not have expandRows,
        // this will safeguard for errors.
        return []
      }
      const createGroupTemplate = () => {
        // This computed value will return an array of these objects.
        return {
          _empty: true, // Temporary helper
          materialCode: null,
          // "reservation row" is not used in UI design?
          materialCodeItems: []
        }
      }
      const materialCodeGroups = []
      let group = createGroupTemplate() // Create the first empty template
      for (const row of this.orderDetails.expandRows) {
        // Check if we need to start populating a new group
        if (row.rowType === 'materialCodeGroup' && group.materialCode) {
          // Push the finished group and start a new group from empty template
          delete group._empty
          materialCodeGroups.push(group)
          group = createGroupTemplate()
        }
        // We have something so the template is not empty
        group._empty = false

        if (row.rowType === 'materialCodeGroup') {
          group.materialCode = row.materialCode
          continue
        }

        if (row.rowType === 'reservationRow') {
          // Unused by UI design?
          continue
        }

        if (row.rowType === 'materialCodeItem') {
          // Just copy the whole thing. This is the pre-processed or
          // "enriched" tracking status sub-item.
          const item = { ...row }
          group.materialCodeItems.push(item)
          continue
        }
      }
      // Check if we need to add the final group.
      // There might be an easier way to do this whole process.
      if (!group._empty) {
        delete group._empty
        materialCodeGroups.push(group)
      }
      return materialCodeGroups
    }
  },
  data () {
    return {
      materialCodes: [], // FIXME: Deprecated
      label: {} // FIXME: Deprecated
    }
  }
}
</script>

<style scoped>

</style>
