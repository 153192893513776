<template>
  <span>
    <mobile-material-code-item
      icon="icon-truck-1"
      :icon-color="deliveryIconColor"
      :title="topTransportDetails.addressAndStatus"
      :subtitle="topTransportDetails.date"
      :debug-stage="materialCodeItem._stage"
      :expandable="expandable"
      :sap-flow-type="materialCodeItem.sapFlowType"
      @click="expand"
    />
    <mobile-transport-details-item
      v-if="expanded"
      :transportDetails="transportDetails"
      class="list-area"
    />
  </span>
  <!--
    TODO: Should the route items be listed here or in MobileMaterialCodeItem?
  -->
</template>

<script>
import { messages } from '@/utils/strings'
// import colorVariables from '@/assets/css/custom.scss'
import MobileMaterialCodeItem from '@/components/OrderDetails/MobileMaterialCodeItem'
import { formatResponseDate } from '@/components/StatusMonitor/formatters'
import { getDeliveryColor } from '@/components/StatusMonitor/colors'
import MobileTransportDetailsItem from '@/components/OrderDetails/MobileTransportDetailsItem'

// If min length is 1 then the item is already shown as topDetails so no need for dropdown.
// So the length of tranportDetails array must be at least 2 for the dropdown to open
const TRANSPORT_DETAILS_MIN_LENGTH = 1

export default {
  name: 'MobileDeliveryItem',
  components: {
    MobileMaterialCodeItem,
    MobileTransportDetailsItem
  },
  props: {
    materialCodeItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  filters: {
    formatResponseDate
  },
  computed: {
    deliveryIconColor: function () {
      return getDeliveryColor(this.materialCodeItem)
    },
    topTransportDetails: function () {
      const topDetails = this.materialCodeItem?.transportDetails?.[0] ?? {}
      if (topDetails.address || topDetails.status || topDetails.date_time) {
        return {
          addressAndStatus: topDetails.address + ' - ' + messages.getLabelStatus({ status: topDetails.status }),
          date: topDetails.date_time
        }
      }
      return false
    },
    expandable () {
      const transportDetails = this.materialCodeItem?.transportDetails ?? []
      if (transportDetails.length > TRANSPORT_DETAILS_MIN_LENGTH) {
        return true
      }
      return false
    },
    transportDetails () {
      const transportDetails = this.materialCodeItem.transportDetails
      return transportDetails
    }
  },
  methods: {
    expand () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped lang="scss">
.list-area {
  // 80px is the same level as the text next to picture.
  margin-left: 80px;
}
</style>
