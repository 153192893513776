<template>
  <b-list-group-item
    class="d-flex flex-row p-0 border-0 order-list-item"
    @click="goTo"
  >
    <div
      class="bg-yellow border-bottom border-color"
      data-context="Item leftside colored border"
    ></div>
    <div
      class="text-left border-bottom text-container border-color"
      data-context="List element containing 'summary' of order list item"
      :data-show-date="showDate"
      :data-delivery-date="deliveryDate"
      :data-eta="eta"
      :data-is-eta="deliveryDateIsEta"
      :data-info-text-color="infoTextColor || 'NoInfoTextColor'"
      :data-service-order="serviceOrder || 'NoServiceOrder'"
    >
      <!-- Info text row -->
      <div
        class="border-color in-one-line"
        data-context="Item info row"
      >
        <span v-if="showDate">
          <span v-if="deliveryDateIsEta" :style="{ color: deliveryDateColor }" data-variable="deliveryDateIsEta">
            {{ eta | formatResponseDate | formatMissing(messages.getLabelNoEtaDate()) }} {{ messages.getFieldEta() }}
          </span>
          <span v-else :style="{ color: deliveryDateColor }" data-variable="deliveryDate">
            {{ deliveryDate  || eta | formatResponseDate }}
          </span>
          <span v-if="deliveryStatus" :style="{ color: deliveryDateColor || colorVariables.etaColor }">{{ deliveryStatus }}</span>
        </span>
      </div>

      <!-- Equipment description and code and service order -->
      <div
        v-if="showEquipmentInformation"
      >
        <div class="middle-text border-color title" data-context="Equipment Description or Code">
          {{ equipmentInfo }}
        </div>
        <div
          class="bottom-text border-color subtitle"
          data-context="Service Order"
          @click="logItem"
        >
        {{ serviceOrder }}
        </div>
      </div>
      <!-- fall back to material code and description etc. -->
      <div
        v-else
      >
        <div class="middle-text border-color title smaller-title">
          <span data-context="Material Code">{{ materialCode }}</span>
          <span v-if="materialDescription" data-context="Material Description">: {{ materialDescription }}</span>
        </div>
        <div class="bottom-text border-color subtitle">
          <span>{{ serviceOrderFallback.value }}</span>
        </div>
      </div>
      <div
       class="smaller-title"
       v-if="!expandable"
      >
      {{ quantityForCurrentStage }}
      </div>

      <!-- Service order description row -->

    </div>
    <div
      class="icon-container d-flex justify-content-center align-items-center border-color"
    >
      <icon-hexagon-mask>
        <div
          id="icon-content"
          class="border-color"
          v-if="iconContent"
        >{{ iconContent }}
        </div>
      </icon-hexagon-mask>
    </div>
  </b-list-group-item>
</template>

<script>
/**
 * Mobile view "OrderRow" item.
 *
 * This is used in the main OrderListMobile, but also in the OrderDetails view.
 */
import actionMap from '@/constants/action-map'
import { messages } from '@/utils/strings'
import IconHexagonMask from '@/components/icons/IconHexagonMask'
import { formatResponseDate, formatMissing } from '@/components/StatusMonitor/formatters'
import { getInfoTextColor, getDeliveryDateColor } from '@/components/StatusMonitor/colors'
import { showEquipmentInformation, getServiceOrderFallback, trackingStatusStages } from '@/components/StatusMonitor/tracking-status'
import { quantityForStage } from '@/components/StatusMonitor/tracking-status-rows'
import colorVariables from '@/assets/css/custom.scss'

export default {
  name: 'OrderListItem',
  components: { IconHexagonMask },
  props: {
    orderListItem: { // TrackingStatusItem-like object.
      required: false,
      type: Object,
      default () { // Dummy object before routes are fixed.
        return {
          _placeholder: true, // Remove this once this route is handled properly
          trackingStatusItem: {
            _orderRowSummary: {}
          }
        }
      }
    }
  },
  data () {
    return {
      colorVariables,
      messages
    }
  },
  mounted () {
    if (this.orderListItem._placeholder) {
      console.warn('Route path parameters not implemented, redirecting to Status Monitor main page.')
      this.$router.replace({ name: 'StatusMonitor' })
    }
  },
  filters: {
    formatResponseDate,
    formatMissing
  },
  methods: {
    logItem () {
      // FIXME: Debugger function. Remove also from the template where this is used.
      // console.log({ orderListItem: this.orderListItem })
    },
    goTo () {
      const to_ = {
        name: 'OrderDetails',
        params: { orderId: this.serviceOrder || '__MISSING_SERVICE_ORDER__', orderDetails: this.orderListItem }
      }
      this.$router.push(to_)
    }
  },
  computed: {
    deliveryDateColor () {
      const orderRowSummary = {
        ...this.orderListItem.trackingStatusItem._orderRowSummary,
        _stage: this.orderListItem.trackingStatusItem._orderRowSummary?.stage
      }
      return getDeliveryDateColor(orderRowSummary)
    },
    showEquipmentInformation: function () {
      return showEquipmentInformation({ equipmentCode: this.equipmentCode, serviceOrder: this.serviceOrder })
    },
    deliveryDate: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.deliveryDate
    },
    eta: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.eta
    },
    deliveryDateIsEta: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.deliveryDateIsEta
    },
    materialCode: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.materialCode
    },
    materialDescription: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.materialDescription
    },
    serviceOrder: function () {
      return this.orderListItem.trackingStatusItem.serviceOrder
    },
    equipmentCode: function () {
      return this.orderListItem.trackingStatusItem.equipmentCode
    },
    equipmentDescription: function () {
      return this.orderListItem.trackingStatusItem.equipmentDescription
    },
    equipmentInfo: function () {
      return this.equipmentDescription ?? this.equipmentCode
    },
    attentionBorderColor: function () {
      return null
    },
    deliveryStatus: function () {
      if (this.orderListItem.trackingStatusItem._orderRowSummary?.stage === actionMap.RESERVATION_ITEM) {
        return actionMap.TO_BE_PURCHASED
      } else if (this.orderListItem.trackingStatusItem._orderRowSummary?.deliveryStatus === actionMap.TO_BE_PICKED_UP) {
        return actionMap.READY_TO_GO
      }
      return ''
    },
    iconContent: function () {
      return null
    },
    showDate: function () {
      return true
    },
    infoTextColor: function () {
      // FIXME: Depends on acting point and to be puchased
      return getInfoTextColor({ deliveryStatus: this.deliveryStatus, actionMap })
    },
    serviceOrderFallback: function () {
      return getServiceOrderFallback(this.orderListItem.trackingStatusItem._orderRowSummary)
    },
    expandable: function () {
      return !!this.serviceOrder
    },
    quantityForCurrentStage: function () {
      const currentStage = this.orderListItem.trackingStatusItem._orderRowSummary?.stage
      return quantityForStage(currentStage, this.orderListItem.trackingStatusItem)
    },
    stage: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.stage
    },
    trackingStatusStages: function () {
      return trackingStatusStages
    },
    purchaseOrderConfirmationCategory: function () {
      return this.orderListItem.trackingStatusItem._orderRowSummary?.purchaseOrderConfirmationCategory
    }
  }
}
</script>

<style scoped lang="scss">
#order-list-item {
  height: 80px;
}
.border-color {
  border-bottom-color: $secondary!important;
}
.bg-yellow {
  width: 10px;
  max-height: 100%;
}
.text-container {
  padding-left: 22px;
  width: 100%;
}
.middle-text {
  font-size: 20px;
}
.bottom-text {
  color: $gray-600;
}
.no-service-order {
  color: $gray-400;
}
.icon-container {
  width: 95px;
  max-height: 100%;
}
#icon-content {
  font-family: 'KONE Information', 'Avenir', Helvetica, Arial, sans-serif;
}
.smaller-title {
  font-size: 16px;
}
.in-one-line {
  white-space: nowrap;
}
.green {
  color: $green;
}
</style>
