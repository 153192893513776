<template>
  <b-nav-item
    :aria-label="messages.getActionGoBack()"
    :title="messages.getActionGoBack()"
    @click="goBack"
  >
    <i
      class="icon-arrow-left text-white"
    />
  </b-nav-item>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'BackButton',
  data () {
    return {
      messages
    }
  },
  methods: {
    goBack () {
      // Note that this does not check what the previous page was.
      // We can assume it was the StatusMonitor with the searh query parameter set.
      // Currently /order-details/:id route does not work anyway and it redirects
      // back to the main StatusMonitor route so if that is ever implemented
      // this has to be fixed to go back to the StatusMonitor page and not to
      // the actual `window.history.go(-1)`
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
