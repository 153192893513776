<template>
  <base-top-bar>
    <template
      v-slot:left
    >
      <back-button />
    </template>
    {{ messages.getLabelOrderDetails() }}
  </base-top-bar>
</template>

<script>
import { messages } from '@/utils/strings'
import BaseTopBar from '../navigation/BaseTopBar'
import BackButton from '../navigation/BackButton'

export default {
  name: 'OrderDetailsTopBar',
  data () {
    return {
      messages
    }
  },
  components: { BackButton, BaseTopBar }
}
</script>

<style scoped>
</style>
